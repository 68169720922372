import React, { Component } from "react";
import styled from "styled-components";
import * as L from '../Utils/Lang';
import * as Widget from "../Components/Widget";
import Link from './Link';

class ProductGridItem extends Component {
    render() {
        let { extraCss = '', item = {} } = this.props;
        return (
            <Wrapper extraCss={extraCss} to={`/product/${item.id}`}>
                <Container img={item.image || 'black'}>
                    <div className="graphic">
                        <div className="price">
                            <span>{`${L.s('from-price-en')}`}</span>
                            {`NT$${item.price || '---'}`}
                            <span>{`${L.s('from-price-tw')}`}</span>
                        </div>
                    </div>
                    <div className="title">{L.d(item, 'display_name') || 'No title'}</div>
                    <div className="button">
                        <Widget.BasicButton>{L.s('create-sim-card')}</Widget.BasicButton>
                    </div>
                </Container>
            </Wrapper>
        )
    }
}

const Wrapper = styled(Link)`
    text-decoration: none;
    box-shadow: 0 2px 15px 0 rgba(155,155,155,0.50);
    margin: 20px;
    padding: 15px;
    max-width: 259px;
    width: 100%;

    @media screen and (max-width: 650px) {
        max-width: 215px;
    }

    @media screen and (max-width: 560px) {
        max-width: 259px;
    }

    ${props => props.extraCss};
`;

const Container = styled.div`
    max-width: 229px;
    & > .graphic {
        background-color: #d0d0d0;
        background-image: url(${props => props.img});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        position: relative;
        margin-bottom: 10px;
        width: 100%;
        height: 280px;

        & > .price {
            position: absolute;
            top: 0px;
            left: 0;

            padding: 5px;
            min-width: 80px;
            background: rgba(255,255,255,0.80);
            text-align: center;
            font-size: 14px;
            color: #0075C1;
            letter-spacing: -0.08px;
        }

        @media screen and (max-width: 650px) {
            height: 262px;
        }

        @media screen and (max-width: 560px) {
            height: 280px;
        }
    }

    & > .title {
        margin-bottom: 20px;
        text-align: center;
        font-size: 16px;
        color: #575757;
    }

    & > .button {
        display: flex;
        justify-content: center;
    }
`;

export default ProductGridItem;