import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../Selectors';
import * as L from '../Utils/Lang';
import Link from './Link';

class ProductGrid extends React.Component {
  render() {
    let { extraCss, products } = this.props;

    return (
      <Wrapper extraCss={extraCss}>
        <h1 className="title">提供服務的地區</h1>

        <div className="content">
          {products.map(p => <ProductItem key={p.id} item={p} />)}
          <ProductItem extraCss="height: 0; overflow: hidden; margin-top: 0; margin-bottom: 0;" />
          <ProductItem extraCss="height: 0; overflow: hidden; margin-top: 0; margin-bottom: 0;" />
          <ProductItem extraCss="height: 0; overflow: hidden; margin-top: 0; margin-bottom: 0;" />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 50px 20px 40px;
  background: #f6f6f6;

  & > .title {
    margin-bottom: 10px;
    color: #3f8dcf;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }

  & > .content {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 690px) {
    padding: 25px 10px 40px;
  }
`;

class ProductItem extends React.Component {
  render() {
    let { extraCss = '', item = {} } = this.props;

    return (
      <ProductItem.Wrapper
        extraCss={extraCss}
        image={item.image}
        to={`/product/${item.id}`}
      >
        <div className="image" />

        <div className="price">
          <span>{`${L.s('from-price-en')}`}</span>
          {`NT$${item.price || '---'}`}
          <span>{`${L.s('from-price-tw')}`}</span>
        </div>

        <div className="content">
          <div className="title">{L.d(item, 'display_name') || 'No title'}</div>

          <div className="description">
            {L.d(item, 'description') || 'No content'}
          </div>

          <div className="button">BUY IT!</div>
        </div>

        <div className="rwd-content">
          <div className="rwd-title">
            {L.d(item, 'display_name') || 'No title'}
          </div>

          <div className="rwd-sub-content">
            <div className="rwd-price">
              <span>{`${L.s('from-price-en')}`}</span>
              {`NT$${item.price || '---'}`}
              <span>{`${L.s('from-price-tw')}`}</span>
            </div>

            <div className="rwd-button">BUY IT!</div>
          </div>
        </div>
      </ProductItem.Wrapper>
    );
  }

  static Wrapper = styled(Link)`
    flex-shrink: 0;
    position: relative;
    width: 285px;
    margin: 20px;
    text-decoration: none;

    & > .image {
      width: 100%;
      height: 475px;
      border-radius: 10px;
      background-color: #d0d0d0;
      background-image: url(${props => props.image});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    }

    & > .price {
      position: absolute;
      top: 30px;
      left: 0;
      height: 40px;
      border-radius: 0 20px 20px 0;
      padding: 5px 15px;
      background-image: linear-gradient(
        90deg,
        #0b65b5 0%,
        #0071d3 0%,
        #2b96e5 100%
      );
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;

      & > span {
        font-size: 14px;
      }
    }

    & > .content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 0 0 10px 10px;
      padding: 15px;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      overflow: hidden;
      z-index: 2;
      color: #fff;

      &::before {
        content: '';
        position: absolute;
        top: -328px;
        left: 0;
        width: 285px;
        height: 475px;
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)
          ),
          url(${props => props.image});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        filter: blur(3px);
        z-index: -1;
      }

      & > .title {
        width: 100%;
        overflow: hidden;
        font-size: 22px;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & > .description {
        width: 100%;
        height: 50px;
        margin-bottom: 5px;
        overflow: hidden;
        font-size: 16px;
        line-height: 25px;
        white-space: pre-wrap;
      }

      & > .button {
        align-self: flex-end;
        width: 100px;
        height: 35px;
        border-radius: 5px;
        border: 2px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
      }
    }

    & > .rwd-content {
      display: none;
      color: #555555;
      font-size: 18px;
      font-weight: 600;

      & > .rwd-sub-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .rwd-price {
          & > span {
            font-size: 12px;
          }
        }

        & > .rwd-button {
          border-radius: 5px;
          border: 1px solid #3f8dcf;
          padding: 3px 9px;
          color: #238ddd;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    @media screen and (max-width: 690px) {
      width: 160px;
      margin: 10px 5px;

      & > .image {
        width: 100%;
        height: 320px;
        margin-bottom: 8px;
      }

      & > .price {
        display: none;
      }

      & > .content {
        display: none;
      }

      & > .rwd-content {
        display: block;
      }
    }

    ${props => props.extraCss};
  `;
}

export default connect(
  (state, ownProps) => ({
    products: Selectors.getProductList(state),
  }),
  null
)(ProductGrid);
