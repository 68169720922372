import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import * as Icon from "./Icon";
import Selectors from '../Selectors';
import * as Widget from "./Widget";

/* 
  !! notice: this is a temp component just for sbir demo,
  including fake data & infos
*/

const SBIR_CARD_DATA = {
  '8985200060788075071':
  {
    originCountry: 'China/ HongKong/ Macau/ Taiwan',
    originQuota: "1GB",
    originDays: "3", 
  },
  '8985200060788075089':
  {
    originCountry: 'China/ HongKong/ Macau/ Taiwan',
    originQuota: "2GB",
    originDays: "5", 
  },
  '8985200060788075097':
  {
    originCountry: 'China/ HongKong/ Macau/ Taiwan',
    originQuota: "2GB",
    originDays: "8", 
  },
  '8985200060788075105':
  {
    originCountry: 'Japan/ Korea/ Taiwan',
    originQuota: "1GB",
    originDays: "3", 
  },
  '8985200060788075113':
  {
    originCountry: 'Japan/ Korea/ Taiwan',
    originQuota: "2GB",
    originDays: "5", 
  },
  '8985200060788075121':
  {
    originCountry: 'Japan/ Korea/ Taiwan',
    originQuota: "2GB",
    originDays: "8", 
  },
  '8985200060788075139':
  {
    originCountry: 'Singapore/ Malaysia/ Thailand/ Taiwan',
    originQuota: "1GB",
    originDays: "3", 
  },
  '8985200060788075147':
  {
    originCountry: 'Singapore/ Malaysia/ Thailand/ Taiwan',
    originQuota: "2GB",
    originDays: "5", 
  },
  '8985200060788075154':
  {
    originCountry: 'Singapore/ Malaysia/ Thailand/ Taiwan',
    originQuota: "2GB",
    originDays: "8", 
  },
  '8985200060788075162':
  {
    originCountry: 'New Zealand/ Australia/ Taiwan',
    originQuota: "1GB",
    originDays: "30", 
  }, 
}

const COUNTRY_PER_UNIT_PRICE = {
  'japan':{ 1: 68, 2: 136},
  'korea':{ 1: 68, 2: 136},
  'hongkong':{ 1: 68, 2: 136},
  'macau':{ 1: 68, 2: 136},
  'singapore':{ 1: 68, 2: 136}, 
  'malaysia':{ 1: 68, 2: 136},
  'thailand':{ 1: 68, 2: 136},
  'taiwan':{ 1: 68, 2: 136},
  'china':{ 1: 68, 2: 136},
  'hk_mo':{ 1: 68, 2: 136},
  'cn_hk_mo':{ 1: 68, 2: 136},
  'sg_my':{ 1: 68, 2: 136},
  'nz_au':{ 1: 68, 2: 136}
}

class DepositDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      originCountry: "",
      originQuota: "",
      originDays: "",

      country: 'japan',
      quota: 1,
      days: 1,

      total: null,

      showSuccess: false,
      showSpinner: false,
      hint: "",
    };
  }

  render() {
    let { onClose, open, navActions } = this.props;
    let {
      cardNumber,
      originCountry,
      originQuota,
      originDays,

      country,
      quota,
      days,
      total,

      showSuccess,
      showSpinner,
      hint,
    } = this.state;
    return (
      <Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="close" onClick={onClose}>
              <Icon.Close color="#575757" size={26} />
            </div>

            <>
              <div className="content">
                <div className="title">
                  {
                    "加值"
                  }
                </div>
                <FormField css="margin-bottom: 20px;">
                  <div className="label">卡號</div>
                  <input
                    value={cardNumber}
                    onChange={e =>
                      this.setState({ cardNumber: e.target.value })
                    }
                  />
                  <div
                    onClick={
                      cardNumber ?
                      (
                        () => this._checkOriginData() 
                      ):(
                        () => 0
                      )
                    }
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#b77c7c',
                      color: 'white',
                      padding: 5,
                      margin: 5,
                      fontSize: 10,
                    }}>
                      查詢
                  </div>
                </FormField>
                <FormField css="margin-bottom: 20px;">
                  <div className="label" >目前國家</div>
                  <p>{originCountry}</p>
                </FormField>
                <FormField css="margin-bottom: 20px;">
                  <div className="label">流量/天</div>
                  <p>{originQuota}</p>
                </FormField>
                <FormField css="margin-bottom: 20px; padding-bottom: 10px; border-bottom: 2px solid #eee;">
                  <div className="label">目前天數</div>
                  <p>{originDays}</p>
                </FormField>

                <FormField css="margin-bottom: 20px;">
                  <div className="label">新增國家</div>
                  <select name="country" onChange={e => this.setState({country: e.target.value, total: null, hint: ''})}>
                    <option value="japan">日本</option>
                    <option value="korea">韓國</option>
                    <option value="hongkong">香港</option>
                    <option value="macau">澳門</option>
                    <option value="singapore">新加坡</option>
                    <option value="malaysia">馬來西亞</option>
                    <option value="thailand">泰國</option>
                    <option value="taiwan">台灣</option>
                    <option value="china">中國</option>
                    <option value="hk_mo">香港＋澳門</option>
                    <option value="cn_hk_mo">中國+香港＋澳門</option>
                    <option value="sg_my">新加坡+馬來西亞</option>
                    <option value="nz_au">紐西蘭+澳洲</option>
                  </select>
                </FormField>
                <FormField css="margin-bottom: 20px;">
                  <div className="label">流量/天</div>
                  <select
                    name="gn"
                    onChange={e => this.setState({quota: Number(e.target.value), total: null, hint: ''})}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                  <div className="label" style={{marginLeft: 5}}>GB</div> 
                </FormField>
                <FormField >
                  <div className="label">天數</div>
                  <input
                    type="number"
                    onChange={e => {
                      this.setState({ days: Number(e.target.value), total: null, hint: ''})
                    }
                    }
                    defaultValue="1"
                    max='100000'
                    min='1'
                  />
                </FormField>

              </div>

              <div className="button-bar">
                {hint && <div className="hint">{hint}</div>}
                {total && <div className="total">小計: NTD {total} 元</div>}
                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                    total ? (
                      <button
                        onClick={() =>
                        this._modifyCart('add')()
                        .then(() => this._checkout())
                        .catch(err => console.warn('error: ',err))
                      }
                      >送出
                      </button>

                      
                    ):(
                      <button onClick={() => this._getTotal()}>計算價錢</button>
                    ) 
                  )}
              </div>
            </>
            
          </div>
        )}
      </Dialog>
    );
  }

  _checkOriginData = () => {
    let {cardNumber} = this.state
    if (!SBIR_CARD_DATA[cardNumber]) {
     this.setState({hint: '無此卡號，請再確認一次',originCountry: '', originDays: '', originQuota: '',}) 
    } else {
      let originCountry = SBIR_CARD_DATA[cardNumber].originCountry
      let originDays = SBIR_CARD_DATA[cardNumber].originDays
      let originQuota = SBIR_CARD_DATA[cardNumber].originQuota
      this.setState({originCountry, originDays, originQuota, hint: ''})
    }
    
  }

  _getTotal = () => {
    let {country, quota, days} = this.state;
    let price_per_day = COUNTRY_PER_UNIT_PRICE[country][quota];

    if (!days ) {
      return this.setState({ hint: "天數不可為空！" });
    }
    this.setState({total: (price_per_day * days) + 100})
  }

  _modifyCart = action => () => {
    let { itemId, profile, appActions } = this.props;
    let { quota, days, cardNumber } = this.state;

    let values = {
      quantity: "1",
      data: `${quota}G`,
      days,
    }
    if (!cardNumber || !days ) {
      this.setState({ hint: "卡號或天數不可為空！" });
      return Promise.reject();
    }

    if (!SBIR_CARD_DATA[cardNumber]) {
      this.setState({hint: '請再確認一次卡號',originCountry: '', originDays: '', originQuota: '',}) 
      return Promise.reject(); 
    } 
     
    if (values.days > this.MaximumOfDays) {
      this.setState({ hint: '天數超過上限' });
      return Promise.reject();
    }

    if (!profile) {
      this.setState({ hint: '請先登入！' });
      return Promise.reject();
    }

    this.setState({ showSpinner: true });
    return appActions.cart
      .configItem(action, {
        id: 1, // fake(Japan)
        data: values,
      })
      .then(() => {
        if (action === 'add') {
          this.setState({
            addToCartResult: {
              success: true,
            },
          });
        }
      })
      .then(() => this.setState({ showSpinner: false }))
      .catch(error => {
        if (action === 'add') {
          this.setState({
            addToCartResult: {
              success: false,
              error: error,
            },
          });
        }
      });
  };

  _checkout = () => {
    let { quota, days } = this.state;
    let { appActions, navActions, cartData } = this.props;

    let values = { // fake data for sbir demo!
      address: "新北市新北區新北路744號",
      delivery_location_type: "airport",
      email: "revteltest@gmail.com",
      payment_type: "ecpay",
      phone: "0987654321",
      receiver: "Deposit",
      store_id: "airport-TSA",
      store_name: "台北松山-統振櫃檯",
      taxIDNumber: "",
      update_profile: false,
    }
     // Success
      this.setState({ errMsg: '' });
      this.setState({ showSpinner: true });
      appActions.cart.checkout(values)
        .then(order => {
          navActions.push(`/order?id=${order.id}`);
        })
        .then(() => this.setState({ showSpinner: false }))
        .catch(err =>
          console.warn(err)
        );
  };
}

const FormField = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;


  & > .label {
    margin-right: 13px;
    font-size: 14px;
    color: #575757;
  }

  & > p {
    font-size: 14px;
    color: orangered; 
  }

  & > input {
    flex: 1;
    border: 1px solid #dadada;
    padding: 15px;
    font-size: 14px;
    height: 35px;
    color: #575757;

    :focus {
      outline: none;
    }
  }

  & > .selector {
    .text {
      margin-left: 10px;
      font-size: 14px;
      color: #575757;
    }
  }

  ${props => props.css};
`;

let Dialog = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;

  transform: translateX(-100vw);
  transition: 0s ease-in transform 0.5s;

  &.active {
    transform: translateX(0);
    transition: 0s ease-in transform 0s;
  }

  & .backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: ${props => (props.open ? 1 : 0)};
    transition: 0.2s ease-in opacity;
  }

  & .container {
    background-color: white;
    width: 100%;
    max-width: 594px;
    min-height: 200px;
    max-height: calc(100% - 60px);
    border-radius: 5px;
    z-index: 1001;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px 40px 20px;

    & > .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    & > .content {
      overflow: auto;
      max-width: 420px;
      width: 100%;

      & .title {
        text-align: center;
        margin-bottom: 20px;
        white-space: pre-wrap;
        line-height: 24px;
        font-size: 16px;
        color: #575757;
      }
    }

    & .button-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;
      max-width: 420px;
      width: 100%;

      & > .hint {
        flex: 1;
        margin-right: 5px;
        font-size: 14px;
        color: red;
      }

      & > .total {
        flex: 1;
        margin-right: 5px;
        font-size: 14px;
        color: orange;
      }

      & > button {
        border: 1px solid #bd9f84;
        background: #ffffff;
        text-align: center;
        cursor: pointer;
        min-width: 116px;
        min-height: 40px;

        font-size: 16px;
        color: #bd9f84;

        :focus {
          outline: none;
        }
      }
    }

    @media screen and (max-width: 700px) {
      min-width: initial;
      padding: 40px 60px;
    }

    @media screen and (max-width: 550px) {
      padding: 25px;
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    cartData: Selectors.cart.getData(state),
  }),
  ActionCreator
)(DepositDialog);
