import React from 'react';
import styled from 'styled-components';
import { withPage } from '../Page';
import * as Widget from '../Components/Widget';
import StepSection from "../Components/StepSection";
import ProductGridsSection from "../Components/ProductGridsSection";
import DepositSection from "../Components/DepositSection";

class ProductListingPage extends React.Component {
  render() {
    return (
      <Wrapper>
        <StepSection />
        <ProductGridsSection />
        <DepositSection/>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding-top: 150px;
`;

export default withPage(ProductListingPage);
