import React, { Component } from "react";
import styled from "styled-components";

const StepData = [
    { number: "1", title: "選擇你要去的\n國家" },
    { number: "2", title: "選擇您所需要\n的流量、天數、數量" },
    { number: "3", title: "加入購物車\n ↓ \n計算價格" },
    { number: "4", title: "確認訂單\n ↓ \n選擇取件方式\n ↓ \n選擇付款方式" },
    { number: "5", title: "完成訂單" },
];

class StepSection extends Component {
    render() {
        return (
            <Wrapper>
                <Container>
                    <div className="section-title">如何客製化上網卡？</div>
                    <StepContainer>
                        {
                            StepData.map((data, i) => {
                                return (
                                    <div key={"step" + i}>
                                        <div className="step-row-block">
                                            <div className="graphic">
                                                <div className="line" />
                                                <div className="step">{data.number}</div>
                                            </div>
                                            <div className="text">{data.title}</div>
                                        </div>

                                        <div className="step-col-block">
                                            <div className="graphic">
                                                <div className="line" />
                                                <div className="step">{data.number}</div>
                                            </div>
                                            <div className="text">
                                                {data.title}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </StepContainer>
                </Container>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    width: 100vw;
    min-height: 450px;
    background: #F6FCFD;
    padding: 60px 25px;
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: 1220px;
    
    & > .section-title {
        margin-bottom: 80px;
        text-align: center;
        font-weight: 400;
        font-size: 50px;
        color: #9b8c7b;
        letter-spacing: 3px;

        @media screen and (max-width: 768px) {
            font-size: 40px;
        }

        @media screen and (max-width: 600px) {
            font-size: 30px;
        }

        @media screen and (max-width: 550px) {
            margin-bottom: 50px;
        }
    }
`;

const StepContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & .step-row-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 180px;

        & > .graphic {
            display: flex;
            align-items: center;

            position: relative;
            margin-bottom: 30px;
            width: 180px;
            min-height: 50px;

            & > .line {
                flex: 1;
                height: 1px;
                background: #979797;
            }

            & > .step {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: #a39080;
                font-size: 20px;
                color: #FFFFFF;

                position: absolute;
                top: 0px;
                left: 65px;
            }
        }

        & > .text {
            max-width: 150px;
            min-height: 110px;

            white-space: pre-wrap;
            text-align: center;
            line-height: 22px;

            font-size: 14px;
            color: #988a77;
        }

        @media screen and (max-width: 900px) {
            max-width: 150px;
            & > .graphic {
                width: 150px;
                & > .step {
                    left: 50px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            max-width: 130px;
            & > .graphic {
                width: 130px;
                & > .step {
                    left: 40px;
                }
            }
        }

        @media screen and (max-width: 650px) {
            max-width: 100px;
            & > .graphic {
                width: 100px;
                & > .step {
                    width: 40px;
                    height: 40px;
                    font-size: 14px;
                    left: 30px;
                }
            }
        }

        @media screen and (max-width: 550px) {
            display: none;
        }
    }

    & .step-col-block {
        display: flex;
        align-items: center;

        & > .graphic {
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            position: relative;
            width: 50px;
            height: 180px;

            & > .line {
                flex: 1;
                width: 1px;
                background: #979797;
            }

            & > .step {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: #a39080;
                font-size: 20px;
                color: #FFFFFF;

                position: absolute;
                top: 65px;
            }
        }

        & > .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 150px;
            min-height: 100px;

            white-space: pre-wrap;
            text-align: center;
            line-height: 22px;

            font-size: 14px;
            color: #988a77;
        }

        @media screen and (min-width: 551px) {
            display: none;
        }
    }

    @media screen and (max-width: 550px) {
        flex-direction: column;
    }
`;

export default StepSection;
