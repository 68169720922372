import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import ProductGridItem from "./ProductGridItem";
import ProductGrid from './ProductGrid';

class ProductGridsSection extends Component {
    render() {
        let { products } = this.props;
        return (
            <Wrapper>
                <Container>
                    <div className="section-title">提供服務的地區</div>
                    <div className="content">
                        {
                            products.map((item, i) =>
                                <ProductGridItem
                                    key={"product-grid-item" + i}
                                    item={item}
                                />
                            )
                        }
                        <ProductGridItem extraCss="height: 0; overflow: hidden; margin-top: 0; margin-bottom: 0; padding: 0;" />
                        <ProductGridItem extraCss="height: 0; overflow: hidden; margin-top: 0; margin-bottom: 0; padding: 0;" />
                        <ProductGridItem extraCss="height: 0; overflow: hidden; margin-top: 0; margin-bottom: 0; padding: 0;" />
                    </div>
                </Container>
                {/* <ProductGrid /> */}
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    padding: 120px 25px 150px;
    width: 100vw;
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: 1220px;

    & > .section-title {
        margin-bottom: 60px;
        text-align: center;
        font-size: 30px;
        color: #0075C1;
        letter-spacing: 1.8px;
    }

    & > .content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
`;

export default connect(
    (state, ownProps) => ({
        products: Selectors.getProductList(state),
    }),
    ActionCreator
)(ProductGridsSection);