import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "../Components/Widget";
import DepositDialog from './DepositDialog';

class DepositSection extends Component {
    state = {
        showDialog: false,
    }
    render() {
        let {showDialog} = this.state;
        return (
            <Wrapper>
                <Container>
                    <div className="section-title">我已經購買SIM卡，想要加值更多國家</div>
                    <div className="button" onClick={() => this.setState({showDialog: true})}>
                        <Widget.BasicButton>加值</Widget.BasicButton>
                    </div>
                </Container>
               
                <DepositDialog 
                open={showDialog}
                onClose={() => this.setState({ showDialog: false })}
                />
               
                
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    width: 100vw;
    min-height: 450px;
    background: #F6FCFD;
    padding: 60px 25px;
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: 1220px;

    & > .button {
        display: flex;
        justify-content: center;
    }

    & > .section-title {
        margin-bottom: 80px;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        color: #368FA1;
        letter-spacing: 3px;

        @media screen and (max-width: 768px) {
            font-size: 40px;
        }

        @media screen and (max-width: 600px) {
            font-size: 30px;
        }

        @media screen and (max-width: 550px) {
            margin-bottom: 50px;
        }
    }
`;


export default DepositSection;
